import { createSlice } from '@reduxjs/toolkit';
// utils
import { ApiEndpoints } from '../../config';
import axios, { webHookAxiosInstace } from '../../utils/axios';
//
import { dispatch, store, useSelector } from '../store';

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: null,
  contacts: [],
  conversations: [],
  activeConversationId: null,
  participants: [],
  recipients: [],
  isConversationLoading: false,
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    startConversationLoading(state){
      state.isConversationLoading = true
    },

    stopConversationLoading(state){
      state.isConversationLoading=false
    },

    clearContacts(state) {
      state.contacts = [];
    },

    clearConversation(state){
      state.conversations=[];
      state.contacts =[];
      state.activeConversationId=null
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CONTACT SSUCCESS
    getContactsSuccess(state, action) {
      const contacts = action.payload;
      state.contacts = contacts;
      state.isLoading = false;

    },
    // Update latest Contacts to the contacts list
    setContactsSuccess(state, action) {
      state.contacts = [...state.contacts, action.payload];
      state.isLoading = false;
    },
    // GET CONVERSATIONS
    getConversationsSuccess(state, action) {
      state.conversations = action.payload;
      // state.isLoading = false;
    },

    setConversationsSuccess(state, action) {
      const newConversation = action.payload?.message?.data ? action.payload?.message?.data : action.payload;

      const newWhatsAppMessageId =
        newConversation?.message?.data?.whatsAppMessageId || newConversation?.whatsAppMessageId;

      if (!newWhatsAppMessageId) {
        return;
      }

      const existingIndex = state.conversations.findIndex(
        (conversation) =>
          (conversation?.whatsAppMessageId || conversation?.message?.data?.whatsAppMessageId) === newWhatsAppMessageId
      );

      if (existingIndex !== -1) {
        state.conversations.splice(existingIndex, 1, newConversation);
      } else {
        state.conversations.push(newConversation);
      }

      if (state.contacts.length > 0) {
        const activeContactIndex = state.contacts.findIndex(
          conve => conve?.conversationId === state.activeConversationId?.conversationId
        );
  
        if (activeContactIndex !== -1) {
          const lastConversation = state.conversations[state.conversations.length - 1];
  
          state.contacts[activeContactIndex].lastMessage = lastConversation;  
        } else {
          console.log('No matching active contact found.');
        }
      }
      state.isLoading = false; 
    },

    // GET CONVERSATION
    setActiveConversation(state, action) {
      const conversation = action.payload;

      if (conversation) {
        state.activeConversationId = conversation;
      } else {
        state.activeConversationId = null;
      }
    },

    // ON SEND MESSAGE
    onSendMessage(state, action) {
      const conversation = action.payload;
      const { conversationId, messageId, message, contentType, attachments, createdAt, senderId } = conversation;

      const newMessage = {
        id: messageId,
        body: message,
        contentType,
        attachments,
        createdAt,
        senderId,
      };

      state.conversations.byId[conversationId].messages.push(newMessage);
    },

    markConversationAsReadSuccess(state, action) {
      const { conversationId } = action.payload;
      const conversation = state.conversations.byId[conversationId];
      if (conversation) {
        conversation.unreadCount = 0;
      }
    },

    // GET PARTICIPANTS
    getParticipantsSuccess(state, action) {
      const participants = action.payload;
      state.participants = participants;
    },

    // RESET ACTIVE CONVERSATION
    resetActiveConversation(state) {
      state.activeConversationId = null;
    },

    addRecipients(state, action) {
      const recipients = action.payload;
      state.recipients = recipients;
    },
  },
});

// Reducer
export default slice.reducer;
export const {
  getConversationsSuccess,
  setActiveConversation,
  setConversationsSuccess,
  setContactsSuccess,
  clearContacts,
  clearConversation,
} = slice.actions;
// Actions
export const { addRecipients, onSendMessage, resetActiveConversation } = slice.actions;

// ----------------------------------------------------------------------

export function getContacts(id, endpoint) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await webHookAxiosInstace.get(endpoint+id);
      dispatch(slice.actions.getContactsSuccess(response.data));
      const state = store.getState();
      const activeConversationId = state.chat.activeConversationId;
      if (!!activeConversationId) {
        dispatch(
          setActiveConversation(
            response?.data?.filter((item) => item.conversationId === activeConversationId?.conversationId)
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }finally{
      dispatch(slice.actions.stopLoading());
    }
  };
}

// ----------------------------------------------------------------------

export function getConversations(conversationId) {
  return async () => {
    dispatch(slice.actions.startConversationLoading());
    try {
      const response = await webHookAxiosInstace.get(
        ApiEndpoints.GET_ALL_CHAT_HISTORY_BY_CONVERSATION_ID + conversationId
      );
      dispatch(slice.actions.getConversationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }finally{
      dispatch(slice.actions.stopConversationLoading());
    }
  };
}

// ----------------------------------------------------------------------

export function getConversation(conversationKey) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/chat/conversation', {
        params: { conversationKey },
      });
      dispatch(slice.actions.setActiveConversation(response.data.conversation));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function markConversationAsRead(conversationId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.get('/api/chat/conversation/mark-as-seen', {
        params: { conversationId },
      });
      dispatch(slice.actions.markConversationAsReadSuccess({ conversationId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getParticipants(conversationKey) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/chat/participants', {
        params: { conversationKey },
      });
      dispatch(slice.actions.getParticipantsSuccess(response.data.participants));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function chatLogout (){
  return ()=>{
    dispatch(slice.actions.clearConversation())
  }
}
